@import "./fonts.css";
@import "./colors.css";
@import "./sizes.css";

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: var(--regular-font-family-1);
  letter-spacing: 0.3px;
  line-height: 1.5;
  margin: 0 auto;
}

* {
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

a {
  color: var(--color-green-5);
}

a:hover {
  text-decoration: underline;
}

.columns-2 {
  column-count: 2;
  margin: 0;
  padding: 0;
}

/* Mobile  */
@media screen and (max-width: 479px) {

}

/* Tablet  */
@media screen and (min-width: 480px) and (max-width: 799px) {

}
