.carousel-root {
  display: flex;
}
.carousel-root .carousel-slider {
  order: 2;
}

.carousel-root .carousel-slider .carousel-status {
  color: var(--color-green-5);
  font-size: 14px;
}

.carousel-slider .slider-wrapper {
  margin-bottom: 50px;
}
.carousel-slider .slider-wrapper .slider {
  min-height: 300px;
}
.carousel-root .carousel:not(.carousel-slider) {
  max-width: 210px;
  order: 1;
}
.carousel-slider .slider .slide {
  text-align: left;
  padding-left: 6%;
  padding-right: 5%;
  line-height: 1.55;
  letter-spacing: 0.5px;
}
.carousel-slider .slider .slide,
.carousel-slider .slider .slide.selected {
  padding-bottom: 50px;
}
.carousel-slider .slider .slide.selected > div {
  padding-bottom: 30px;
}
.carousel-slider .slide ol li {
  margin-bottom: 8px;
}
.carousel-slider .slide .boxed {
  list-style-position: inside;
  padding: 0;
  margin-bottom: 20px;
}
.carousel-slider .slide .boxedItem,
.carousel-slider .slide .boxed li {
  border: 1px solid var(--color-green-5);
  padding: 20px 20px 20px 40px;
  margin-bottom: 20px;
  border-radius: 6px;
  text-indent: -21px;
}
.carousel-slider .slide .boxedItem {
  padding: 0;
  margin-bottom: 20px;
  text-indent: unset;
  padding: 20px;
}
.carousel .thumbs-wrapper {
  margin: 0;
}
.carousel .thumbs {
  white-space: normal;
  transform: none !important;
  padding: 0;
  margin: 0;
  width: 100%;
}
.carousel .thumb {
  width: auto !important;
  cursor: pointer;
  margin: 0;
  margin-bottom: 8px;
  padding: 4px;
  display: block;
  border-bottom: 3px solid transparent;
  color: var(--color-bright-blue-0);
}
.carousel .thumb,
.carousel .thumb.selected,
.carousel .thumb:hover,
.carousel .thumb:focus {
  border: none;
  border-bottom: 2px solid transparent;
  color: var(--color-white);
}
.carousel .thumb.selected {
  color: var(--color-green-5);
  background-color: var(--color-blue-0);
  padding-left: 10px;
  padding-right: 10px;
}
.carousel .thumb.selected:after {
  content: " \27A4";
  float: right;
  margin-left: 10px;
}
.carousel .control-next.control-arrow::before {
  border-left-color: var(--color-black);
  margin-right: -30px;
}
.carousel .control-prev.control-arrow::before {
  border-right-color: var(--color-black);
  margin-left: -30px;
}
.carousel .control-arrow {
  width: 50px;
}
.carousel:not(.carousel-slider) .control-arrow {
  display: none;
}
.carousel .control-dots .dot {
  background-color: var(--color-green-5);
  width: 12px;
  height: 12px;
}
.carousel .control-dots .dot.selected {
  border-color: var(--color-blue-0);
}

/* Mobile  */
@media screen and (max-width: 479px) {

.carouselKeyPractice {
  flex-direction: column;
}

.carouselKeyPractice .carousel:not(.carousal-slider) {
  max-width: none;
  z-index: 100;
}

.carouselKeyPractice .carousel .thumbs {
  max-width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}

.carouselKeyPractice .carousel .thumb {
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
}
}
