@import '../../theme/colors.css';

.homePage .contentBox {
  scroll-margin-top: 140px;
}

.homePage .keyPractices .title.rule {
  border-color: var(--color-green-5);
  border-width: 2px;
  color: var(--color-white);
}

.homePage .aboutUs {
  background: var(--color-white);
  background-image: url("../../assets/images/chess_d.png");
  background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0) 35%,
      rgba(255, 255, 255, 0.6) 45%,
      rgba(255, 255, 255, 1) 55%
    ),
    url("../../assets/images/chess_d.png") no-repeat 100% 0;
  background-size: auto 100%;
}
.homePage .aboutUs .title {
  padding-right: 45%;
  margin-top: -40px;
  padding-top: 50px;
}
.homePage .aboutUs .orgNameSub {
  font-weight: 700;
}
.homePage .aboutUs .content {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 8px;
}

.homePage .keyPractices {
  background-color: var(--color-blue-1);
  color: var(--color-white);
}

.homePage .keyPractices a,
.homePage .keyPractices a:link,
.homePage .keyPractices a:hover,
.homePage .keyPractices a:active,
.homePage .keyPractices a:focus {
  color: var(--color-white);
}

.homePage .industryExposure,
.homePage .keyLinks {
  background-color: var( --color-blue-4);
}

.homePage #keyLinks a {
  color: var(--color-blue-0);
}

.homePage #keyLinks a:hover {
  color: var(--color-blue-0a);
}

.row {
  display: flex;
  flex-wrap: nowrap;
  margin: -8px;
}

.row > * {
  margin: 8px;
}

.split-8-4 > *:first-child {
  flex: 6 0 50%;
}

.split-8-4 > *:last-child {
  flex-grow: 6 0 50%;
}

/* Mobile  */
@media screen and (max-width: 479px) {
  .homePage .aboutUs {
    margin-top: 0;
  }
  
  .homePage .aboutUs .title {
    padding-top: 18px;
  }

  .homePage .aboutUs .content {
    padding-right: 16px;
  }
}

/* Mobile & Tablet */
@media screen and (max-width: 799px) {
  .homePage .contentBox {
    scroll-margin-top: 80px;
  }

  .homePage > .contentBox:first-of-type {
    padding-top: 39px;
  }
  .row {
    flex-direction: column;
  }

  .split-8-4 > *:first-child {
    flex: 6 0 100%;
  }
  
  .split-8-4 > *:last-child {
    flex-grow: 6 0 100%;
  }

  .homePage #contactUs,
  .homePage #keyLinks {
    margin-bottom: 40px;
  }
}

/* Tablet  */
@media screen and (min-width: 480px) and (max-width: 799px) {
  .homePage .contentBox {
    scroll-margin-top: 107px;
  }
}

/* Laptop  */
@media screen and (min-width: 480px) {
  .homePage .aboutUs {
    padding-right: 37%;
    margin-top: -30px;
  }

  .homePage .aboutUs .content {
    padding: 8px 30px;
    padding-left: 0;
  }
}
