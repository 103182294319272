.appHeader {
  background-color: rgba(var(--color-rgb-white), 0.85);
  position: sticky;
  top: 0;
  display: grid;
  box-shadow: var(--box-shadow-1);
  border-bottom: 16px solid var(--color-blue-1);
  background: linear-gradient(
    to bottom,
    var(--color-blue-3) -30%,
    var(--color-white) 50%
  );
  z-index: 1200;
}

.headerInner {
  display: flex;
  max-width: var(--size-1200);
  padding: 0 25px;
  width: 100%;
  margin: 0 auto;
}

.logoWrap {
  display: flex;
  flex-grow: 1;
  max-width: 40%;
  min-width: 400px;
}

.orgName {
  font-family: var(--title-font-family-1);
  flex-grow: 1;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  color: var(--color-blue-0);
  min-height: 92px;
}

.orgName h2 {
  margin: 33px 0 0 0;
  line-height: 0.8;
  white-space: nowrap;
  text-transform: uppercase;
}

.orgName h3 {
  margin: 0 0 12px 0;
}

.logoBox {
  padding: 10px;
  margin: 10px 0;
}

.logo {
  height: 100%;
  width: 90px;
  background: url("../../assets/images/ca_logo.png") no-repeat 0 0;
  background-size: contain;
}

.burgorIcon {
  float: right;
  margin-bottom: 22px;
}

.nav {
  flex-grow: 1;
  align-self: flex-end;
  position: relative;
}

.nav ul {
  display: flex;
}

.nav ul li a {
  padding: 5px 10px;
}

.nav a:hover {
  text-decoration: none;
}

.navList {
  justify-content: flex-end;
}

.selected {
  border-bottom: 2px solid var(--color-green-5);
  padding-bottom: 3px;
}

/* Mobile styles */
.navList.open {
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 28px;
  background-color: var(--color-white);
  padding: 10px;
  border-radius: 4px;
  border: var(--border-style-1);
  box-shadow: var(--box-shadow-1);
  min-width: 190px;
}

.navList.close {
  display: none;
}

.navList .menuItem {
  padding: 5px;
}

.navList .menuItem a {
  color: var(--color-blue-0);
  text-decoration: none;
}

/* Desktop only */
@media screen and (min-width: 799px) {
  .orgName h2 {
    font-size: 28px;
    margin-top: 22px;
  }
  .orgName h3 {
    font-size: 22px;
  }
}

/* Mobile  */
@media screen and (max-width: 479px) {
  .headerInner {
    padding: 0 10px;
  }

  .logoBox {
    padding: 10px 0;
  }

  .logoWrap {
    min-width: 280px;
  }

  .orgName {
    padding-right: 0;
  }
}

/* Tablet  */
@media screen and (min-width: 480px) and (max-width: 799px) {
  .orgName {
    padding-right: 15px;
  }
}

/* Mobile & Tablet */
@media screen and (max-width: 799px) {
  .logoWrap {
    min-width: 245px;
  }

  .logo {
    width: 45px;
  }

  .orgName {
    min-height: auto;
  }

  .orgName h2 {
    font-size: 16px;
    margin-top: 18px;
  }

  .orgName h3 {
    font-size: 14px;
  }

  .burgorIcon {
    margin-bottom: 8px;
  }
}
