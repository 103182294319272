:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-blue-0a: #0071BC;
  --color-blue-0: #093f62;
  --color-blue-1: #3381a4;
  --color-blue-2: #a6dcf2;
  --color-blue-3: #bfe7f5;
  --color-blue-4: #eef8fc;
  --color-bright-blue-0: #0073ee;
  --color-orange-0: #ff9900;
  --color-yellow-0: #ffff00;
  --color-green-0: #152b01;
  --color-green-1: #063601;
  --color-green-2: #234602;
  --color-green-3: #39690b;
  --color-green-4: #528522;
  --color-green-5: #81b84d;
  --color-green-6: #74da16;
  --color-green-7: #7bff00;

  --color-gray-1: #fbfbfb;
  --color-gray-2: #efefef;
  --color-gray-3: #dbdbdb;
  --color-gray-4: #cccccc;
  --color-gray-5: #999999;
  --color-gray-6: #666666;
  --color-gray-7: #333333;
  --color-gray-8: #222222;
  --color-gray-9: #111111;

  --color-rgb-white: 255, 255, 255;

  --box-shadow-1: 0px 8px 8px -4px rgba(0, 0, 0, 0.2);

  --border-style-1: 1px solid rgba(0, 0, 0, 0.1);
  --border-style-2: 1px solid rgba(0, 0, 0, 0.2);
  --border-style-3: 1px solid var(--color-blue-0);
}
