.appFooter {
  background-color: var(--color-blue-1);
  padding: 25px;
  border-top: 1px solid #999999;
  color: var(--color-gray-3);
}

.footerInner {
  max-width: var(--size-1200);
  width: 100%;
  margin: 0 auto;
}

.footerLinks {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.flink {
  padding: 16px;
}

.flink p {
  margin-bottom: 4px;
}

.flink a,
.flink a:hover,
.flink a:active,
.flink a:focus {
  color: var(--color-green-6);
}

.appFooter .contactUs {
  padding: 0 30px;
  color: var(--color-white);
  min-height: auto;
  line-height: 1.2;
}

.appFooter .contactUs .copyRight {
  color: var(--color-blue-2);
  font-style: italic;
}

.appFooter .contactUs .title {
  color: var(--color-white);
  margin-bottom: 14px;
}
.appFooter .contactUs .flink {
  padding: 0;
}

/* Mobile  */
@media screen and (max-width: 479px) {
  .footerLinks {
    flex-direction: column;
    text-align: center;
  }

  .footerLinks .flink {
    padding: 4px;
  }

  .footerLinks .flink h3 {
    border-bottom: 1px solid #7fd2d1;
  }
}