.hamburger {
  width: 48px;
  height: 100%;
  padding: 6px 10px 12px;
  border: var(--border-style-1);
  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-1);
  cursor: pointer;
}

.hamburger:hover{
  background-color: var(--color-gray-2);
}

.hamburger .burger {
  height: 2px;
  background-color: var(--color-black);
  width: 24px;
  margin-top: 6px;
}